import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { HtmlHead, ResponsiveImage, Link } from "@cdv/jazz-web/components";
import { Typography, Grid, Container, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Head = () => (
  <HtmlHead title="Kontakt | Metodické centrum pro dopravní psychologii">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

export default function Page(req, res) {
  const [data, setData] = useState<object>();
  useEffect(() => {
    let request = new XMLHttpRequest();

    request.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        setData(JSON.parse(request.response)["data"]);
      }
    };
    request.open(
      "GET",
      "https://metodicke-centrum-admin.cdvinfo.cz/secure/api/news/show/" + req.params.id,
      true
    );
    request.send();
  }, []);
  const aCss = {
    paddingTop: "130px !important",
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
    h1: {
      fontWeight: "700",
      color: "#f35f24",
      fontSize: "28px",
    },
    h2: {
      fontSize: "23px",
      color: "#3f79c6",
    },
  };
  return (
    <Layout isIndex={false}>
      <Container sx={aCss}>
        {data ? <div dangerouslySetInnerHTML={{ __html: data["content"] }}></div> : ""}
      </Container>
    </Layout>
  );
}
